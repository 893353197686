<template>
  <Wrapper>
    <header>{{ $tc('confirm.edit.subsidiary') }}</header>
    <InputStyled :required="!$v.name.required">
      <input v-model.trim="$v.name.$model" :placeholder="'Subsidiary'" />
    </InputStyled>
    <p class="errors" v-if="$v.name.$dirty && !$v.name.required">{{ $tc('messages.required') }}</p>
    <p class="error" v-if="!$v.name.minLength">Field must have at least {{ $v.name.$params.minLength.min }} letters.</p>
    <ButtonsGroup>
      <ButtonStyled :disabled="isApplyButtonDisabled" @click="editSubsidiaryName">
        {{ $tc('actions.apply') }}
      </ButtonStyled>
      <ButtonStyled :disabled="isLoading" color="grey" @click="$root.$emit('closeOverlay')">
        {{ $tc('actions.cancel') }}
      </ButtonStyled>
    </ButtonsGroup>
  </Wrapper>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import SUBSIDIARY_QUERY from '#/graphql/organizations/subsidiary/show.gql'
import UPDATE_SUBSIDIARY_MUTATION from '#/graphql/organizations/subsidiary/update.gql'

import { FlashMessages } from '@common/singletons'
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { buttonReset, flexCenter } from '@styles/mixins'

const InputStyled = styled('div')`
  width: 100%;
  padding: 1rem 0;
  input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    color: ${props => props.theme.colors.white};
    background: ${props => chroma(props.theme.colors.white).alpha(0.1).css()};
    border-bottom: 1px solid
      ${props => (props.required ? chroma(props.theme.colors.red).alpha(0.8).css() : chroma(props.theme.colors.green).alpha(0.8).css())};
    transition: border-color 0.5s ease;

    &:focus {
      border-color: ${props => props.theme.colors.primary};
    }

    &::placeholder {
      color: ${props => chroma(props.theme.colors.white).alpha(0.5).css()};
    }
  }
`

const ButtonStyled = styled('button')`
  ${props => buttonReset(props)}
  ${flexCenter}
  margin: .5rem;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background: ${props => props.theme.colors.orange};
  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primaryLighter};
  }
  &:disabled {
    color: ${props => chroma(props.theme.colors.muted).brighten(1).css()};
    background: ${props => chroma(props.theme.colors.muted).darken(1).css()};
  }
`

const ButtonsGroup = styled('div')`
  display: flex;
  flex-direction: row;
  float: right;
`

const Wrapper = styled('div')`
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  padding-top: 50px;

  .errors {
    color: red;
  }
`

export default {
  props: {
    args: {
      type: Object,
    },
  },
  components: {
    Wrapper,
    InputStyled,
    ButtonsGroup,
    ButtonStyled,
  },
  mixins: [validationMixin],
  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
  },
  data() {
    return {
      name: '',
      initialName: '',
      isLoading: false,
    }
  },
  computed: {
    organizationID() {
      return this.args.organizationID
    },
    subsidiary() {
      return this.args.subsidiary
    },
    isApplyButtonDisabled() {
      return !this.$v.name.required || !this.$v.name.minLength || this.isLoading || this.name === this.initialName
    },
  },
  watch: {
    subsidiary: {
      handler(subsidiary) {
        this.name = subsidiary.name
        this.initialName = subsidiary.name
      },
      immediate: true,
    },
  },
  methods: {
    async editSubsidiaryName() {
      await this.$apollo.mutate({
        mutation: UPDATE_SUBSIDIARY_MUTATION,
        variables: {
          where: {
            id: this.subsidiary.id,
          },
          data: {
            name: this.name,
          },
        },
        update: store => {
          const { subsidiary } = store.readQuery({
            query: SUBSIDIARY_QUERY,
            variables: {
              where: {
                id: this.subsidiary.id,
              },
            },
          })

          subsidiary.name = this.name

          store.writeQuery({
            query: SUBSIDIARY_QUERY,
            variables: {
              where: {
                id: this.subsidiary.id,
              },
            },
            data: {
              subsidiary,
            },
          })
        },
      })

      FlashMessages.$emit('success', this.$t('messages.entityCreatedSuccessfully', { entity: this.$tc('subsidiary', 1) }), {
        timeout: 3000,
      })

      this.$root.$emit('closeOverlay')
    },
  },
}
</script>
